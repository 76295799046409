import React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'

export const query = graphql`
  query page404 {
    prismicPage(uid: { eq: "404" }) {
      ...PrismicPageFragment
    }
  }
`

const NotFoundPage = ({ data }) => {
  const page = data.prismicPage
  const pageData = page.data

  let images = pageData.page_image?.gatsbyImageData
  if (pageData.page_image.gatsbyImageData) {
    images = withArtDirection(getImage(pageData.page_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(pageData.page_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  return (
    <Layout>
      <div className='grid h-full max-h-screen-1/2 grid-cols-1 grid-rows-1 overflow-hidden'>
        {pageData.page_image.gatsbyImageData && (
          <div className='art-directed col-span-full row-span-full h-full'>
            <GatsbyImage className='h-full w-full object-cover' image={images} alt={pageData.page_image.alt || ''} />
          </div>
        )}
        <div className='hero relative col-span-full row-span-full flex w-full flex-col justify-center px-5 pt-28 pb-20 text-center md:mx-auto md:max-w-screen-xl md:text-left'>
          <div className='mx-auto flex flex-col items-stretch justify-center gap-4 md:max-w-screen-md md:items-center'>
            <PrismicRichText field={pageData.page_title.richText} />
            <PrismicRichText field={pageData.page_text.richText} />
            <PrismicLink className='button' field={pageData.page_button_link}>
              {pageData.page_button_text}
            </PrismicLink>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)

export const Head = ({ data, location }) => {
  const pageData = data.prismicPage.data
  return <Seo pathname={location.pathname} title={pageData.meta_title ? pageData.meta_title : pageData.page_title.text} description={pageData.meta_description} image={pageData.page_image.url} />
}
